<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div class="d-flex align-center pt-0">
        <div class="table-v-action-button mt-2 mr-2" @click="doLoad()">
          <v-icon>mdi-refresh</v-icon>
          Atualizar
        </div>
        <year-select style="max-width: 126px;" :max="maxYear" @change="doLoad" />
      </div>
    </v-card-text>
    <v-card-text class="table-wrap p-0">
      <v-container fluid class="py-0">
        <v-row>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Dispêndio
          </v-col>
          <v-col :cols="6">
            <span>Recursos Humanos</span> {{ safeData.dispendio_repassado.rh | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Serviços Técnicos de Terceiros</span> {{ safeData.dispendio_repassado.servicos | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Equipamento e Software</span> {{ safeData.dispendio_repassado.equipamentos | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Treinamento</span> {{ safeData.dispendio_repassado.treinamento | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Obras Civis</span> {{ safeData.dispendio_repassado.obras | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Viagens</span> {{ safeData.dispendio_repassado.viagens | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Livros ou Periódicos</span> {{ safeData.dispendio_repassado.livros | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Outros Correlatos</span> {{ safeData.dispendio_repassado.correlatos | toCurrency }}
          </v-col>
          <v-col :cols="6">
            <span>Material de Consumo</span> {{ safeData.dispendio_repassado.material | toCurrency }}
          </v-col>
          <v-col :cols="6" />
          <v-col :cols="12">
            <strong>Total de Dispêndio {{ safeData.dispendio_repassado.total | toCurrency }}</strong>
          </v-col>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Demais Custos
          </v-col>
          <v-col :cols="12">
            <span>Custo incorrido pela instituição</span> {{ safeData.demais_custos.incorrido | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor total repassado para a Instituição</span> {{ safeData.demais_custos.total_repassado | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor antecipado para o próximo ano</span> {{ safeData.demais_custos.antecipado_proximo_ano | toCurrency }}
          </v-col>
          <v-col :cols="12">
            <span>Valor antecipado do ano anterior</span> {{ safeData.demais_custos.antecipado_ano_anterior | toCurrency }}
          </v-col>
          <v-col :cols="12" class="text-center text-uppercase text-h6">
            Totais
          </v-col>
          <v-col :cols="12">
            <strong>Valor gasto no ano base {{ safeData.totais.gasto_anoBase | toCurrency }}</strong>
          </v-col>
          <v-col :cols="12">
            <strong>Total válido para cumprimento de obrigação no ano base {{ safeData.totais.cumprimento_obrigacao | toCurrency }}</strong>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  components: {
    "year-select": () => import("@/components/year-select.vue"),
  },
  computed: {
    ...mapGetters(['anoBase']),
    maxYear: function () {
      return moment().add(1, 'year').get('year');
    },
    resource: function () {
      const id = this.$route.params.projetoExternoId || '';
      return this.apiResource(`v1/faturamento/projext/gastos/${id}`);
    },
    safeData: function () {
      const handler = {
        get(target, prop) {
          if (typeof target[prop] === 'object' && target[prop] !== null) {
            return new Proxy(target[prop], handler);
          }

          if (prop in target) {
            return target[prop];
          }

          return 0;
        },
      };

      return new Proxy(this.gastos, handler);
    },
    title: function () {
      const title = this.gastos?.projeto?.titulo;
      const projectTitle =  title ? ` de ${title}` : '';
      return `Controle de Gastos${projectTitle}`;
    },
  },
  data: function () {
    return {
      gastos: {},
    };
  },
  methods: {
    doLoad: function (anoBase) {
      let query = `anoBase=${anoBase || this.anoBase}`;
      this.resource.get({ query }).then((response) => {
        if (response.error) {
          this.gastos = {};
        } else {
          this.gastos = response;
        }
      });
    },
  },
  name: "controle-gastos",
}
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  color: #212529;
}

.table-wrap {
  background-color: rgb(244, 244, 244);
}

.row {
  border-collapse: collapse;
}

.row,
.col {
  border-bottom: thin solid rgba(0,0,0,.12);
}

.text-center.text-uppercase.text-h6 {
  background-color: #bedefc;
  color: #3e3e3f;
}
</style>
